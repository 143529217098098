/* Basic editor styles */
.tiptapDashboardMentions {
  flex: 1;
  color: var(--chakra-colors-textBlack);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  .mention {
    color: var(--chakra-colors-accent);
    box-decoration-break: clone;
    padding: 0 0.125rem;
  }
}
